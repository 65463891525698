export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/bf8c220b-f8cb-4bf2-9f86-8b0a5d6d3e77',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugaison.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  appnexus: {
    conjugaison: {
      publisherId: 1131429,
      banniere_basse: { placementId: 12392743 },
      banniere_haute: { placementId: 12392744 },
      pave_haut: { placementId: 12392748 },
      pave_milieu: { placementId: 12392749 },
      inread: { placementId: 12392753 },
      inread_top: { placementId: 26485454 },
    },
  },
  indexExchange: {
    conjugaison: {
      formats: {
        desktop: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
        tablet: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
        mobile: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_nouvelobsconjugaison',
    placementIdInreadTop: 'invibes_nouvelobsconjugaison_top',
  },
  rubicon: {
    conjugaison: {
      siteId: 422580,
      placements: {
        banniere_haute: { tag: 2400796 },
        pave_haut: { tag: 2400800 },
        pave_milieu: { tag: 2400820 },
        inread: { tag: 2400824 },
        inread_top: { tag: 2400824 },
      },
    },
  },
  smart: {
    conjugaison: {
      siteId: 502018,
      pageId: 1567018,
    },
  },
  sublime: {
    conjugaison: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31902 },
        },
        mobile: {
          cover: { zoneId: 31901 },
        },
      },
    },
  },
  teads: {
    conjugaison: { pageId: 117825, placementId: 127924 },
  },
  triplelift: {
    conjugaison: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
          'pave_haut',
          'pave_milieu',
        ],
      },
      inventoryCode: 'LaConjugaison_HB_',
    },
  },
};
