export default {
  slots: '.dfp-slot',
  // Specific to France Inter
  pubstack: '//boot.pbstck.com/v1/tag/de92c6dd-1b14-4d4d-9c1f-662349a3750e',
  refresh: {
    excludedFormats: [],
  },
  // Specific to France Inter
  appnexus: {
    franceinter: {
      publisherId: 2089067,
      banniere_haute: { placementId: 24955395 },
      pave_haut: { placementId: 24955396 },
      pave_bas: { placementId: 24955397 },
      inread: { placementId: 24955398 },
      inread_top: { placementId: 24955398 },
    },
  },
  // Specific to France Inter
  indexExchange: {
    franceinter: {
      formats: {
        desktop: {
          banniere_haute: '809005',
          pave_haut: '809006',
          inread: '809008',
          inread_top: '809008',
          pave_bas: '809007',
        },
        tablet: {
          banniere_haute: '809005',
          pave_haut: '809006',
          inread: '809008',
          inread_top: '809008',
          pave_bas: '809007',
        },
        mobile: {
          banniere_haute: '809021',
          inread: '809020',
          inread_top: '809020',
        },
      },
    },
  },
  // Specific to France Inter
  invibes: {
    placementId: 'invibes_franceinter',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to France Inter
  rubicon: {
    franceinter: {
      siteId: 418076,
      placements: {
        banniere_haute: { tag: 2363622 },
        pave_haut: { tag: 2363624 },
        pave_bas: { tag: 2363626 },
        inread: { tag: 2404330 },
        inread_top: { tag: 2404330 },
      },
    },
  },
  // Specific to France Inter
  smart: {
    franceinter: {
      siteId: 505152,
      pageId: 1574624,
    },
  },
  sublime: {
    franceinter: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to France Inter
  teads: {
    franceinter: { pageId: 145853, placementId: 159821 },
  },
  // Specific to France Inter
  triplelift: {
    franceinter: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'FranceInter_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
