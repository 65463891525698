export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/8b306fc8-029f-4764-a4e1-7c9bce891843',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-konjugation-de.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  refresh: {
    inViewPercentage: 50,
    maxInactivity: 90000,
    minDisplayTime: 15000,
    minSinceActive: 5000,
    minSinceViewed: 5000,
    respectRoadblocks: true,
    tick: 500,
    excludedFormats: [
      'banniere_haute',
      'cover',
      'dhtml',
      'habillage',
      'parallaxe',
      'partenaire_middle',
      'partenaire_bottom',
    ],
  },
  appnexus: {
    konjugation: {
      publisherId: 1131429,
      banniere_basse: { placementId: 18440928 },
      banniere_haute: { placementId: 18440892 },
      pave_haut: { placementId: 18440908 },
      pave_milieu: { placementId: 20354470 },
      inread: { placementId: 18440922 },
      inread_top: { placementId: 26485455 },
    },
  },
  indexExchange: {
    konjugation: {
      formats: {
        desktop: {
          banniere_haute: '819138',
          pave_haut: '819115',
          inread: '8191117',
          inread_top: '8191117',
          banniere_basse: '819116',
          pave_milieu: '819118',
        },
        tablet: {
          banniere_haute: '819138',
          pave_haut: '819115',
          inread: '8191117',
          inread_top: '8191117',
          banniere_basse: '819116',
          pave_milieu: '819118',
        },
        mobile: {
          banniere_haute: '819138',
          pave_haut: '819115',
          inread: '8191117',
          inread_top: '8191117',
          banniere_basse: '819116',
          pave_milieu: '819118',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_diekonjugation',
    placementIdInreadTop: 'invibes_diekonjugation_top',
  },
  smart: {
    konjugation: {
      siteId: 506182,
      pageId: 1577419,
    },
  },
  sublime: {
    konjugation: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31848 },
        },
        mobile: {
          cover: { zoneId: 31847 },
        },
      },
    },
  },
  teads: {
    konjugation: { pageId: 116964, placementId: 126958 },
  },
  rubicon: {
    konjugation: {
      siteId: 324544,
      placements: {
        banniere_haute: { tag: 1683734 },
        pave_haut: { tag: 1683738 },
        pave_milieu: { tag: 1683740 },
        inread: { tag: 2403908 },
        inread_top: { tag: 2403908 },
      },
    },
  },
  triplelift: {
    konjugation: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_diekonjugation_',
    },
  },
};
