/* global document */
export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/6c9d91b6-476b-4385-b76a-cbdcba750b31',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lobs.json',
  smartAdCallConfig: {},
  excludedFormatsHB: [],
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const containerLobs = document.querySelector('.container.ObsRubrique');

    if (containerLobs !== null) {
      containerLobs.style.cssText = 'max-width: 1000px; padding: 15px;';
    }
    banner.classList.remove('dfp-unloaded');
    banner.classList.remove('container-auto');
    banner.style.cssText = 'position: absolute; z-index: 0; margin: auto; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; max-width: none !important;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; width: 1000px;';
  }),
  formats: {
    inimage_seed: {
      desktop: [1, 1],
      tablet: [1, 1],
      mobile: [1, 1],
    },
  },
  appnexus: {
    lobs: {
      publisherId: 1131429,
      banniere_basse: { placementId: 21875747 },
      banniere_haute: { placementId: 21875741 },
      pave_haut: { placementId: 21875748 },
      pave_milieu: { placementId: 21875749 },
      pave_bas: { placementId: 21875750 },
      inread: { placementId: 21875751 },
      inread_top: { placementId: 26485432 },
    },
    refresh: {
      publisherId: 1131429,
      banniere_haute: { placementId: 25287196 },
      banniere_basse: { placementId: 25287195 },
      pave_haut: { placementId: 25287198 },
      pave_milieu: { placementId: 25287199 },
      pave_bas: { placementId: 25287197 },
      inread: { placementId: 25287200 },
      inread_top: { placementId: 26485432 },
    },
  },
  indexExchange: {
    lobs: {
      formats: {
        desktop: {
          banniere_haute: '766726',
          pave_haut: '766702',
          inread: '766725',
          inread_top: '766725',
          banniere_basse: '766728',
          pave_milieu: '766724',
          pave_bas: '766727',
        },
        tablet: {
          banniere_haute: '766726',
          pave_haut: '766702',
          inread: '766725',
          inread_top: '766725',
          banniere_basse: '766728',
          pave_milieu: '766724',
          pave_bas: '766727',
        },
        mobile: {
          inread: '766725',
          inread_top: '766725',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_nouvelobs',
    placementIdInreadTop: 'invibes_nouvelobs_top',
  },
  rubicon: {
    lobs: {
      siteId: 235082,
      placements: {
        banniere_haute: { tag: 1158884 },
        banniere_basse: { tag: 1158886 },
        pave_haut: { tag: 1158888 },
        pave_milieu: { tag: 1158890 },
        pave_bas: { tag: 1158892 },
        inread: { tag: 1505298 },
        inread_top: { tag: 1505298 },
      },
    },
  },
  smart: {
    lobs: {
      siteId: 278833,
      pageId: 1187333,
    },
  },
  sublime: {
    lobs: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31294 },
        },
        mobile: {
          cover: { zoneId: 33651 },
        },
      },
    },
  },
  teads: {
    lobs: { pageId: 114221, placementId: 124064 },
  },
  triplelift: {
    lobs: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'NouvelObs_HB_',
    },
  },
};
