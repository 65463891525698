export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/d9ba314d-246c-48be-9d6c-56115639c58a',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugacion-es.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  appnexus: {
    conjugacion: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388084 },
      banniere_haute: { placementId: 19613649 },
      pave_haut: { placementId: 19613650 },
      pave_milieu: { placementId: 19613652 },
      inread: { placementId: 19613651 },
      inread_top: { placementId: 26485458 },
    },
  },
  indexExchange: {
    conjugacion: {
      formats: {
        desktop: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
        tablet: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
        mobile: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_conjugacion',
    placementIdInreadTop: 'invibes_conjugacion_top',
  },
  rubicon: {
    conjugacion: {
      siteId: 337488,
      placements: {
        banniere_haute: { tag: 1778448 },
        banniere_basse: { tag: 1778438 },
        pave_haut: { tag: 1778456 },
        pave_milieu: { tag: 1778462 },
        inread: { tag: 2403890 },
        inread_top: { tag: 2403890 },
      },
    },
  },
  smart: {
    conjugacion: {
      siteId: 506177,
      pageId: 1577414,
    },
  },
  sublime: {
    conjugacion: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 32116 },
        },
        mobile: {
          cover: { zoneId: 32115 },
        },
      },
    },
  },
  teads: {
    conjugacion: { pageId: 118573, placementId: 128649 },
  },
  triplelift: {
    conjugacion: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_conjugaciones_',
    },
  },
};
