export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/f4499aff-9d2a-41d1-ad61-a185b016abd9',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-coniugazione-it.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  appnexus: {
    coniugazione: {
      publisherId: 1131429,
      banniere_basse: { placementId: 19613642 },
      banniere_haute: { placementId: 19613638 },
      pave_haut: { placementId: 19613639 },
      pave_milieu: { placementId: 19613641 },
      inread: { placementId: 19613640 },
      inread_top: { placementId: 26485457 },
    },
  },
  invibes: {
    placementId: 'invibes_coniugazione',
    placementIdInreadTop: 'invibes_coniugazione_top',
  },
  indexExchange: {
    coniugazione: {
      formats: {
        desktop: {
          banniere_haute: '819123',
          pave_haut: '819129',
          inread: '819130',
          inread_top: '819130',
          banniere_basse: '819131',
          pave_milieu: '819132',
        },
        tablet: {
          banniere_haute: '819123',
          pave_haut: '819129',
          inread: '819130',
          inread_top: '819130',
          banniere_basse: '819131',
          pave_milieu: '819132',
        },
        mobile: {
          banniere_haute: '819123',
          pave_haut: '819129',
          inread: '819130',
          inread_top: '819130',
          banniere_basse: '819131',
          pave_milieu: '819132',
        },
      },
    },
  },
  rubicon: {
    coniugazione: {
      siteId: 337478,
      placements: {
        banniere_haute: { tag: 1778284 },
        banniere_basse: { tag: 1778278 },
        pave_haut: { tag: 1778342 },
        pave_milieu: { tag: 1778356 },
        inread: { tag: 2403886 },
        inread_top: { tag: 2403886 },
      },
    },
  },
  smart: {
    coniugazione: {
      siteId: 506175,
      pageId: 1577412,
    },
  },
  sublime: {
    coniugazione: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 32118 },
        },
        mobile: {
          cover: { zoneId: 32997 },
        },
      },
    },
  },
  teads: {
    coniugazione: { pageId: 118572, placementId: 128648 },
  },
  triplelift: {
    coniugazione: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_coniugazione_',
    },
  },
};
