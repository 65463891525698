/* eslint-disable import/no-cycle */
import glmawApp from '../configs/dev/glmaw-app';
import lemonde from '../configs/glm/lemonde';
import telerama from '../configs/glm/telerama';
import lobs from '../configs/glm/lobs';
import teleobs from '../configs/nealis/prog-tv';
import courrier from '../configs/glm/courrier';
import huffpost from '../configs/glm/huffpost';
import conjugaisonFR from '../configs/nealis/conjugaison-fr';
import konjugationDE from '../configs/nealis/konjugation-de';
import conjugationEN from '../configs/nealis/conjugation-en';
import conjugacionES from '../configs/nealis/conjugacion-es';
import coniugazioneIT from '../configs/nealis/coniugazione-it';
import conjugacaoPT from '../configs/nealis/conjugacao-pt';
import radiofrance from '../configs/radiofrance/radiofrance';
import fip from '../configs/radiofrance/fip';
import francebleu from '../configs/radiofrance/francebleu';
import franceculture from '../configs/radiofrance/franceculture';
import franceinter from '../configs/radiofrance/franceinter';
import francemusique from '../configs/radiofrance/francemusique';
import mouv from '../configs/radiofrance/mouv';

export default () => {
  const sites = [{
    // Config for local test-SPA
    name: 'glmaw',
    regex: '(http?://)?.*localhost',
    specificConfig: glmawApp,
  }, {
    // Config for online test-SPA
    name: 'glmaw',
    regex: '(https?://)?.*glmaw.netlify.app',
    specificConfig: glmawApp,
  }, {
    name: 'lemonde',
    regex: '(https?://)?.*lemonde.fr',
    specificConfig: lemonde,
  }, {
    name: 'lobs',
    regex: '(https?://)?.*nouvelobs.com',
    specificConfig: lobs,
  }, {
    name: 'conjugaison',
    regex: '(https?://)?la-conjugaison.nouvelobs.com',
    specificConfig: conjugaisonFR,
  }, {
    name: 'teleobs',
    regex: '(https?://)?programme-tv.nouvelobs.com',
    specificConfig: teleobs,
  }, {
    name: 'telerama',
    regex: '(https?://)?.*telerama.fr',
    specificConfig: telerama,
  }, {
    name: 'courrier',
    regex: '(https?://)?.*courrierinternational.com',
    specificConfig: courrier,
  }, {
    name: 'huffpost',
    regex: '(https?://)?.*huffingtonpost.fr',
    specificConfig: huffpost,
  }, {
    name: 'konjugation',
    regex: '(https?://)?.*die-konjugation.de',
    specificConfig: konjugationDE,
  }, {
    name: 'theconjugation',
    regex: '(https?://)?.*the-conjugation.com',
    specificConfig: conjugationEN,
  }, {
    name: 'conjugacion',
    regex: '(https?://)?.*conjugacion.es',
    specificConfig: conjugacionES,
  }, {
    name: 'coniugazione',
    regex: '(https?://)?.*coniugazione.it',
    specificConfig: coniugazioneIT,
  }, {
    name: 'conjugacao',
    regex: '(https?://)?.*conjugacao-de-verbos.com',
    specificConfig: conjugacaoPT,
  }, {
    name: 'radiofrance',
    regex: '(https?://)?.*radiofrance.fr',
    specificConfig: radiofrance,
  }, {
    name: 'francebleu',
    regex: '(https?://)?.*francebleu.fr',
    specificConfig: francebleu,
  }, {
    name: 'franceinter',
    regex: '(https?://)?.*franceinter.fr',
    specificConfig: franceinter,
  }, {
    name: 'francemusique',
    regex: '(https?://)?.*radiofrance.fr/francemusique',
    specificConfig: francemusique,
  }, {
    name: 'fip',
    regex: '(https?://)?.*radiofrance.fr/fip',
    specificConfig: fip,
  }, {
    name: 'franceculture',
    regex: '(https?://)?.*radiofrance.fr/franceculture.fr',
    specificConfig: franceculture,
  }, {
    name: 'mouv',
    regex: '(https?://)?.*mouv.fr',
    specificConfig: mouv,
  },

  ];

  return {
    getSpecificConfig(actualSite) {
      let configValue = {};
      sites.forEach((site) => {
        if (site.name === actualSite && site.specificConfig) {
          configValue = { ...site.specificConfig };
        }
      });
      return configValue;
    },
    getSites() {
      return sites;
    },
  };
};
