export default {
  slots: '.dfp-slot',
  // Specific to Mouv
  pubstack: '//boot.pbstck.com/v1/tag/80052158-0aba-43ed-bda6-a3b800d81241',
  refresh: {
    excludedFormats: [],
  },
  // Specific to Mouv
  appnexus: {
    mouv: {
      publisherId: 2089069,
      banniere_haute: { placementId: 24955408 },
      pave_haut: { placementId: 24955409 },
      pave_bas: { placementId: 24955416 },
      inread: { placementId: 24955414 },
      inread_top: { placementId: 24955414 },
    },
  },
  // Specific to Mouv
  indexExchange: {
    mouv: {
      formats: {
        desktop: {
          banniere_haute: '808992',
          pave_haut: '808993',
          inread: '808995',
          inread_top: '808995',
          pave_bas: '808999',
        },
        tablet: {
          banniere_haute: '808992',
          pave_haut: '808993',
          inread: '808995',
          inread_top: '808995',
          pave_bas: '808999',
        },
        mobile: {
          banniere_haute: '809014',
          inread: '809015',
          inread_top: '809015',
        },
      },
    },
  },
  // Specific to Mouv
  invibes: {
    placementId: 'invibes_mouv',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to Mouv
  rubicon: {
    mouv: {
      siteId: 418070,
      placements: {
        banniere_haute: { tag: 2363604 },
        pave_haut: { tag: 2363606 },
        pave_bas: { tag: 2363608 },
        inread: { tag: 2404518 },
        inread_top: { tag: 2404518 },
      },
    },
  },
  // Specific to Mouv
  smart: {
    mouv: {
      siteId: 505143,
      pageId: 1574610,
    },
  },
  sublime: {
    mouv: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to Mouv
  teads: {
    mouv: { pageId: 145849, placementId: 159817 },
  },
  // Specific to Mouv
  triplelift: {
    mouv: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Mouv_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
