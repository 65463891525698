/* global document */
export default {
  slots: '.dfp-slot',
  excludedFormatsHB: ['inimage_seed'],
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');

    banner.removeAttribute('class');
    banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; overflow: hidden;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; max-width: 1000px;';
    banner.style.cssText = 'position: absolute; left: 0;';
  }),
  pubstack: '//boot.pbstck.com/v1/tag/b9725a8b-9a93-4740-aaba-5e304966592e',
  invibes: {
    placementId: 'invibes_lemonde',
    placementIdInreadTop: 'invibes_lemonde_top',
  },
  appnexus: {
    lemonde: {
      publisherId: 1131427,
      banniere_haute: { placementId: 21250115 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 21250107 },
      pave_haut: { placementId: 21250130 },
      pave_milieu: { placementId: 21250131 },
      pave_bas: { placementId: 21250132 },
      inread: { placementId: 21250134 },
      inread_top: { placementId: 26485414 },
    },
    refresh: {
      publisherId: 1131427,
      banniere_haute: { placementId: 24952866 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 24952865 },
      pave_haut: { placementId: 24952864 },
      pave_milieu: { placementId: 24952868 },
      pave_bas: { placementId: 24952869 },
      inread: { placementId: 24952870 },
      inread_top: { placementId: 26485415 },
    },
  },
  indexExchange: {
    lemonde: {
      formats: {
        desktop: {
          banniere_haute: '766706',
          pave_haut: '766700',
          inread: '766705',
          inread_top: '766705',
          banniere_milieu: '766704',
          banniere_basse: '766708',
          pave_milieu: '766703',
          pave_bas: '766707',
        },
        tablet: {
          banniere_haute: '766706',
          pave_haut: '766700',
          inread: '766705',
          inread_top: '766705',
          banniere_milieu: '766704',
          banniere_basse: '766708',
          pave_milieu: '766703',
          pave_bas: '766707',
        },
        mobile: {
          inread: '766705',
          inread_top: '766705',
        },
      },
    },
  },
  rubicon: {
    lemonde: {
      siteId: 222330,
      placements: {
        banniere_haute: { tag: 1093384 },
        banniere_milieu: { tag: 1675456 },
        banniere_basse: { tag: 1093388 },
        pave_haut: { tag: 1093392 },
        pave_milieu: { tag: 1093394 },
        pave_bas: { tag: 1093396 },
        inread: { tag: 1093398 },
        inread_top: { tag: 1093398 },
      },
    },
  },
  smart: {
    lemonde: {
      siteId: 268240,
      pageId: 1187330,
    },
  },
  sublime: {
    lemonde: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  teads: {
    lemonde: { pageId: 116058, placementId: 125950 },
  },
  triplelift: {
    lemonde: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'LeMonde_HB_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
