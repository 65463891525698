export const formats = {
  cover: {
    desktop: ['out-of-page'],
    tablet: ['out-of-page'],
    mobile: ['out-of-page'],
  },
  banniere_basse: {
    desktop: [
      [728, 90],
      [1000, 90],
    ],
    tablet: [
      [728, 90],
      [1000, 90],
    ],
    mobile: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
  },
  banniere_milieu: {
    desktop: [
      [728, 90],
      [970, 250],
      [970, 90],
      [1000, 90],
      [1000, 300],
    ],
    tablet: [
      [728, 90],
      [970, 250],
      [970, 90],
      [1000, 90],
      [1000, 300],
    ],
    mobile: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
  },
  banniere_haute: {
    desktop: [
      [728, 90],
      [970, 250],
      [970, 90],
      [1000, 90],
      [1000, 300],
      [1800, 1000],
    ],
    tablet: [
      [728, 90],
      [970, 250],
      [970, 90],
      [1000, 90],
      [1000, 300],
    ],
    mobile: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
  },
  parallaxe: {
    desktop: ['out-of-page'],
    tablet: ['out-of-page'],
  },
  pave_bas: {
    desktop: [
      [300, 250],
    ],
    tablet: [
      [300, 250],
    ],
    mobile: [
      [300, 250],
    ],
  },
  pave_haut: {
    desktop: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    tablet: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    mobile: [
      [300, 250],
    ],
  },
  pave_milieu: {
    desktop: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    tablet: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    mobile: [
      [300, 250],
    ],
  },
  inread_top: {
    desktop: [
      'fluid',
      [2, 2],
      [300, 250],
      [533, 300],
    ],
    tablet: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
  },
  inread: {
    desktop: [
      'fluid',
      [2, 2],
      [300, 250],
      [533, 300],
    ],
    tablet: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
  },
  native_multiplex: {
    desktop: [
      'fluid',
    ],
    tablet: [
      'fluid',
    ],
    mobile: [
      'fluid',
    ],
  },
  native_multiplex_coldroite: {
    desktop: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
    tablet: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
  },
  native_col_droite: {
    desktop: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
    tablet: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
  },
  native_feed: {
    desktop: [
      'fluid',
      [1, 1],
      [770, 200],
    ],
    tablet: [
      'fluid',
      [1, 1],
      [770, 200],
    ],
    mobile: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
  },
  native_feed_bis: {
    desktop: [
      'fluid',
      [1, 1],
      [770, 200],
    ],
    tablet: [
      'fluid',
      [1, 1],
      [770, 200],
    ],
    mobile: [
      'fluid',
      [1, 1],
      [300, 250],
    ],
  },
};
