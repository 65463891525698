export const formats = {
  cover: {
    desktop: ['out-of-page'],
    tablet: ['out-of-page'],
    mobile: ['out-of-page'],
  },
  banniere_haute: {
    desktop: [
      [728, 90],
      [1000, 90],
      [1000, 200],
    ],
    tablet: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
    mobile: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
  },
  banniere_basse: {
    desktop: [
      [728, 90],
      [1000, 90],
    ],
    tablet: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
    mobile: [
      [300, 50],
      [320, 50],
      [320, 100],
    ],
  },
  pave_bas: {
    desktop: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    tablet: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    mobile: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
  },
  pave_haut: {
    desktop: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    tablet: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    mobile: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
  },
  pave_milieu: {
    desktop: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    tablet: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
    mobile: [
      [160, 600],
      [300, 250],
      [300, 600],
    ],
  },
  inread_top: {
    desktop: [
      'fluid',
      [2, 2],
      [300, 250],
      [533, 300],
    ],
    tablet: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
  },
  inread: {
    desktop: [
      'fluid',
      [2, 2],
      [300, 250],
      [533, 300],
    ],
    tablet: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
    mobile: [
      'fluid',
      [2, 2],
      [300, 250],
    ],
  },
};
