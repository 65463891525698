/* eslint-disable import/no-cycle */
/* global window */
import { log } from '..';
import { config } from '../../../wrapperConfig';
import { phone, tablet } from './device';

export const detectDevice = () => {
  if (phone !== false) {
    return 'mobile';
  }

  if (tablet !== false) {
    return 'tablet';
  }

  return 'desktop';
};

// Target breakpoints for Radio France websites to assign corresponding device
export const detectBreakPoint = () => {
  if (config.group === 'radiofrance') {
    if (window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches) {
      log(`breakpoint for ${config.site}: tablet`);
      config.device = 'tablet';
    } else if (window.matchMedia('(min-width: 1280px)').matches) {
      log(`breakpoint for ${config.site}: desktop`);
      config.device = 'desktop';
    } else {
      log(`breakpoint for ${config.site}: mobile`);
      config.device = 'mobile';
    }
  }
};

// Detect current website, if none detected fallback on 'lemonde' by default
export const detectSite = (siteList, url = window.document.location.hostname) => siteList
  .reduce((accumulator, given) => {
    const regex = new RegExp(given.regex);

    if (regex.test(url)) {
      accumulator = given.name;
    }

    return accumulator;
  }, '') || 'lemonde';

export const detectGroup = () => {
  // Detect if current domain belongs to Radio France
  if (window.location.href.includes('radiofrance') || window.location.href.includes('francebleu')) {
    config.group = 'radiofrance';
    config.site = 'radiofrance';

    const radioFranceWebsites = [
      'francemusique',
      'fip',
      'franceculture',
      'franceinter',
      'mouv',
      'francebleu',
    ];

    for (let i = 0; i < radioFranceWebsites.length; i += 1) {
      if (window.location.href.includes(radioFranceWebsites[i])) {
        config.site = radioFranceWebsites[i];
        break;
      }
    }

    // For testing purposes, add staging domains to assign specific config & group
    const { hostname } = window.document.location;

    if (
      hostname === 'francebleu-frontstaging.uzine.radiofrance.fr'
      || hostname === 'francebleu-frontpreprod.uzine.radiofrance.fr'
      || hostname === 'bleu-cdnpreprod.priv.radiofrance.dev'
      || hostname === 'bleu-cdnstaging.priv.radiofrance.dev'
      || hostname === 'fix-bweb-1421-placeholder-ad-bleu-cdnstaging.priv.radiofrance.dev'
    ) {
      config.site = 'francebleu';
      config.group = 'radiofrance';
    }

    if (
      hostname === 'webrf-cdnstaging.priv.radiofrance.dev'
      || hostname === 'www.radiofrance.fr'
      || hostname === 'www.francebleu.fr'
      || hostname === 'www.franceinter.fr'
      || hostname === 'www.franceculture.fr'
      || hostname === 'www.mouv.fr'
    ) {
      config.group = 'radiofrance';
    }

    log(`load config for ${config.site} of ${config.group}`);
  } else {
    config.group = 'groupelemonde';
    log(`load config for ${config.site} of ${config.group}`);
  }
};
