/* global document */
export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/dfdca06d-b195-4b0b-94dc-4af4fe251bce',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-courrier.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  formats: {
    inread: {
      desktop: [
        [1, 1],
        [2, 2],
        [300, 250],
        [490, 276],
      ],
      tablet: [
        [1, 1],
        [2, 2],
        [300, 250],
      ],
      mobile: [
        [1, 1],
        [2, 2],
        [300, 250],
      ],
    },
    inread_top: {
      desktop: [
        [1, 1],
        [2, 2],
        [300, 250],
        [490, 276],
      ],
      tablet: [
        [1, 1],
        [2, 2],
        [300, 250],
      ],
      mobile: [
        [1, 1],
        [2, 2],
        [300, 250],
      ],
    },
  },
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const mmPage = document.querySelector('body .mm-page');

    banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); overflow: hidden;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto;';
    if (mmPage) {
      mmPage.style.zIndex = 10000;
    }
  }),
  appnexus: {
    courrier: {
      publisherId: 1131425,
      banniere_basse: { placementId: 21249654 },
      banniere_haute: { placementId: 21249655 },
      pave_haut: { placementId: 21249656 },
      banniere_milieu: { placementId: 21249657 },
      pave_milieu: { placementId: 21249659 },
      pave_bas: { placementId: 21249660 },
      inread: { placementId: 21249691 },
      inread_top: { placementId: 26485412 },
    },
  },
  invibes: {
    placementId: 'invibes_courrierinternational',
    placementIdInreadTop: 'invibes_courrierinternational_top',
  },
  indexExchange: {
    courrier: {
      formats: {
        desktop: {
          banniere_haute: '766721',
          pave_haut: '766701',
          inread: '766719',
          inread_top: '766719',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          pave_milieu: '766715',
          pave_bas: '766722',
        },
        tablet: {
          banniere_haute: '766721',
          pave_haut: '766701',
          inread: '766719',
          inread_top: '766719',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          pave_milieu: '766715',
          pave_bas: '766722',
        },
        mobile: {
          banniere_haute: '766721',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          inread: '766719',
          inread_top: '766719',
        },
      },
    },
  },
  rubicon: {
    courrier: {
      siteId: 241802,
      placements: {
        banniere_haute: { tag: 1193322 },
        banniere_milieu: { tag: 1675462 },
        banniere_basse: { tag: 1193328 },
        pave_haut: { tag: 1193330 },
        pave_milieu: { tag: 1193334 },
        pave_bas: { tag: 1193338 },
        inread: { tag: 1193378 },
        inread_top: { tag: 1193378 },
      },
    },
  },
  smart: {
    courrier: {
      siteId: 278827,
      pageId: 1187317,
    },
  },
  sublime: {
    courrier: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31292 },
        },
        mobile: {
          cover: { zoneId: 33649 },
        },
      },
    },
  },
  teads: {
    courrier: { pageId: 114220, placementId: 124063 },
  },
  triplelift: {
    courrier: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'CourrierInternational_HB_',
    },
  },
};
