/* global STAGING_BUILD */
import { config } from './wrapperConfig';
import { loadScript, log } from './lib/utils';
import { isStaging } from './modules/debug';
import {
  getSmartConsentlessData,
  renameInreadId,
  initConfigSmart,
  executeSmartDecision,
} from './modules/smartConsentless';

const execute = () => new Promise((resolve) => {
  getSmartConsentlessData()
    .then(() => {
      loadScript(config.smartConsentlessScript).then(resolve);
      renameInreadId();
      initConfigSmart();
      executeSmartDecision();
      log('execute Smart Ads');
    })
    .catch((err) => {
      log(err);
      resolve();
    });
});

export const init = () => {
  if (isStaging('consentless_staging') === true && STAGING_BUILD === false) {
    log('load staging consentless script');
    loadScript(config.stagingScript);
  } else {
    log('initialize consentless wrapper');
    execute();
  }
};
