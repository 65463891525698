/* eslint-disable import/no-cycle */
/* global document */

export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/fb0126f3-23be-4a48-968e-45c0770f17da',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-huffpost.json',
  smartAdCallConfig: {},
  excludedFormatsHB: [],
  formats: {
    inimage_seed: {
      desktop: [1, 1],
      tablet: [1, 1],
      mobile: [1, 1],
    },
    banniere_milieu: {
      desktop: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    inread: {
      desktop: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
        [630, 355],
      ],
      tablet: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
      ],
    },
    inread_top: {
      desktop: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
        [630, 355],
      ],
      tablet: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [1, 1],
        [2, 2],
        [300, 250],
      ],
    },
    native_feed: {
      desktop: [
        [1, 1],
        [1, 1],
        [770, 200],
      ],
      tablet: [
        [1, 1],
        [1, 1],
        [770, 200],
      ],
      mobile: [
        [1, 1],
        [1, 1],
        [300, 250],
      ],
    },
    native_feed_bis: {
      desktop: [
        [1, 1],
        [1, 1],
        [770, 200],
      ],
      tablet: [
        [1, 1],
        [1, 1],
        [770, 200],
      ],
      mobile: [
        [1, 1],
        [1, 1],
        [300, 250],
      ],
    },
    native_multiplex_coldroite: {
      desktop: [
        [1, 1],
        [1, 1],
        [300, 250],
      ],
      tablet: [
        [1, 1],
        [1, 1],
        [300, 250],
      ],
      mobile: [
        [1, 1],
        [1, 1],
        [300, 250],
      ],
    },
  },
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const huffHomePage = document.querySelector('.body-wrap');
    const huffPageWrapper = document.querySelector('.page-wrapper');
    const huffSection = document.querySelector('.section-name');
    const huffSplash = document.querySelector('.body-splash');
    const huffLife = document.querySelector('.life');

    if (huffHomePage !== null && huffLife == null) {
      const frontPageContent = document.querySelectorAll('.front-page-content');
      const frontPageTop = document.querySelector('.front-page-top');
      const mastHead = document.querySelector('.masthead');
      const subNav = document.querySelector('.subnav-container');
      const advertisement = document.querySelector('.advertisement.ad-leaderboard-flex');

      frontPageContent.forEach((el) => {
        el.style.cssText = 'position: relative; z-index: 1; margin: auto; max-width: 1000px !important; background-color: #fff; padding: 0px 15px;';
      });
      advertisement.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; padding-top: 0px !important; padding-bottom: 0px !important; background-color: #fff;';
      frontPageTop.style.cssText = 'position: relative; z-index: 1; margin: auto; max-width: 1000px !important; background-color: #fff; padding: 0px 15px;';
      mastHead.style.cssText = 'max-width: 1000px; margin: 220px auto 0; z-index: 1; background-color: #fff;';
      subNav.style.cssText = 'display: flex; justify-content: center; max-width: 1000px; margin: auto; overflow: hidden; background-color: #fff;';
    } else if (huffPageWrapper !== null && huffSection !== null) {
      const desktopHeader = document.getElementById('desktop__main_header');
      const innerContainer = document.querySelector('.inner-container.row');
      const sectionName = document.querySelector('.section-name');
      const pageContent = document.querySelector('.page__content__row');
      const masterContainer = document.querySelector('.master-container');

      desktopHeader.style.cssText = 'position: relative; z-index: 2; display: flex; justify-content: center; max-width: 1000px; margin: 220px auto 0; overflow: hidden;';
      innerContainer.style.cssText = 'padding-bottom: 0px; padding-top: 1px; width: 1000px; margin: auto; background-color: #fff';
      banner.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; transform: translateX(-50%);';
      sectionName.style.cssText = 'position: relative; z-index: 1; margin: 0 auto 215px; overflow: hidden; padding-top: 55px;';
      pageContent.parentElement.style.cssText = 'position: relative; z-index: 1; margin: -200px auto 0; max-width: 970px !important;';
      masterContainer.style.cssText = 'z-index: 0';
    } else if (huffPageWrapper !== null && huffSplash !== null) {
      const desktopHeader = document.getElementById('desktop__main_header');
      const innerContainer = document.querySelector('.inner-container.row');
      const pageContent = document.querySelector('.page__content');
      const masterContainer = document.querySelector('.master-container');

      desktopHeader.style.cssText = 'position: relative; z-index: 2; display: flex; justify-content: center; max-width: 1000px; margin: 220px auto 0; overflow: hidden;';
      innerContainer.style.cssText = 'padding-bottom: 0px; padding-top: 1px; width: 1000px; margin: auto; background-color: #fff';
      banner.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; transform: translateX(-50%);';
      pageContent.style.cssText = 'max-width: 970px !important';
      masterContainer.style.cssText = 'z-index: 0';
    } else {
      banner.style.cssText = 'display: none;';
    }
  }),
  appnexus: {
    huffpost: {
      publisherId: 1131426,
      banniere_basse: { placementId: 21249730 },
      banniere_haute: { placementId: 21249737 },
      banniere_milieu: { placementId: 21249742 },
      pave_haut: { placementId: 21249751 },
      pave_milieu: { placementId: 21249755 },
      pave_bas: { placementId: 21249760 },
      native_col_droite: { placementId: 21249776 },
      native_feed: { placementId: 21249796 },
      native_feed_bis: { placementId: 21249799 },
      inread: { placementId: 21249811 },
      inread_top: { placementId: 26485413 },
    },
    refresh: {
      publisherId: 1131426,
      banniere_haute: { placementId: 27321182 },
      banniere_milieu: { placementId: 27321183 },
      banniere_basse: { placementId: 27321181 },
      pave_haut: { placementId: 27321184 },
      pave_milieu: { placementId: 27321185 },
      pave_bas: { placementId: 27321186 },
      inread: { placementId: 27321190 },
      inread_top: { placementId: 27321191 },
      native_col_droite: { placementId: 27321187 },
      native_feed: { placementId: 27321188 },
      native_feed_bis: { placementId: 27321189 },
    },
  },
  invibes: {
    placementId: 'invibes_huffingtonpost',
    placementIdInreadTop: 'invibes_huffingtonpost_inread',
  },
  indexExchange: {
    huffpost: {
      formats: {
        desktop: {
          banniere_haute: '766712',
          pave_haut: '766461',
          inread: '766711',
          inread_top: '766711',
          banniere_milieu: '766710',
          banniere_basse: '766716',
          pave_milieu: '766720',
          pave_bas: '766718',
          native_feed: '766714',
          native_multiplex_coldroite: '766709',
          native_feed_bis: '766713',
        },
        tablet: {
          banniere_haute: '766712',
          pave_haut: '766461',
          inread: '766711',
          inread_top: '766711',
          banniere_milieu: '766710',
          banniere_basse: '766716',
          pave_milieu: '766720',
          pave_bas: '766718',
          native_feed: '766714',
          native_multiplex_coldroite: '766709',
          native_feed_bis: '766713',
        },
        mobile: {
          banniere_haute: '766712',
          inread: '766711',
          inread_top: '766711',
          banniere_basse: '766716',
        },
      },
    },
  },
  rubicon: {
    huffpost: {
      siteId: 242258,
      placements: {
        banniere_haute: { tag: 1197154 },
        banniere_milieu: { tag: 1948584 },
        banniere_basse: { tag: 1197164 },
        pave_haut: { tag: 1197166 },
        pave_milieu: { tag: 1197170 },
        pave_bas: { tag: 1197172 },
        inread: { tag: 1505246 },
        inread_top: { tag: 1505246 },
      },
    },
  },
  smart: {
    huffpost: {
      siteId: 278830,
      pageId: 1187328,
    },
  },
  sublime: {
    huffpost: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31291 },
        },
        mobile: {
          cover: { zoneId: 33648 },
        },
      },
    },
  },
  teads: {
    huffpost: { pageId: 114222, placementId: 124065 },
  },
  triplelift: {
    huffpost: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
          'native_feed',
          'native_multiplex_coldroite',
          'native_feed_bis',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
          'native_feed',
          'native_multiplex_coldroite',
          'native_feed_bis',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'HuffingtonPost_HB_',
    },
  },
};
