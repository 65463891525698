export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/fe39d2b3-a649-4e0b-9ffe-dd678b8ca58e',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugacao-pt.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  appnexus: {
    conjugacao: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388079 },
      banniere_haute: { placementId: 19613623 },
      pave_haut: { placementId: 19613624 },
      pave_milieu: { placementId: 19613626 },
      inread: { placementId: 19613625 },
      inread_top: { placementId: 26485461 },
    },
  },
  indexExchange: {
    conjugacao: {
      formats: {
        desktop: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
        tablet: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
        mobile: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_conjugacaodeverbos',
    placementIdInreadTop: 'invibes_conjugacaodeverbos_top',
  },
  rubicon: {
    conjugacao: {
      siteId: 337462,
      placements: {
        banniere_haute: { tag: 1778190 },
        banniere_basse: { tag: 1778188 },
        pave_haut: { tag: 1778196 },
        pave_milieu: { tag: 1778200 },
        inread: { tag: 2403902 },
        inread_top: { tag: 2403902 },
      },
    },
  },
  smart: {
    conjugacao: {
      siteId: 506181,
      pageId: 1577418,
    },
  },
  // No IDs available yet
  sublime: {
    conjugacao: {
      formats: {
        desktop: {},
        mobile: {},
      },
    },
  },
  teads: {
    conjugacao: { pageId: 118571, placementId: 128647 },
  },
  triplelift: {
    conjugacao: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_conjugacao_',
    },
  },
};
