export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/34ad46cf-e1b1-4bf2-ab08-a453f81497d3',
  // Same as lobs
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lobs.json',
  smartAdCallConfig: {},
  excludedFormatsHB: [],
  appnexus: {
    teleobs: {
      publisherId: 1131429,
      banniere_basse: { placementId: 24923839 },
      banniere_haute: { placementId: 24923838 },
      pave_haut: { placementId: 24923841 },
      pave_milieu: { placementId: 24923842 },
      inread: { placementId: 24923843 },
      inread_top: { placementId: 26485429 },
    },
  },
  indexExchange: {
    teleobs: {
      formats: {
        desktop: {
          banniere_haute: '766732',
          pave_haut: '766729',
          inread: '766731',
          inread_top: '766731',
          banniere_basse: '766730',
          pave_milieu: '766733',
        },
        tablet: {
          banniere_haute: '766732',
          pave_haut: '766729',
          inread: '766731',
          inread_top: '766731',
          banniere_basse: '766730',
          pave_milieu: '766733',
        },
        mobile: {
          banniere_haute: '766732',
          inread: '767784',
          inread_top: '767784',
        },
      },
    },
  },
  // Same as lobs
  invibes: {
    placementId: 'invibes_nouvelobsprogrammetv',
    placementIdInreadTop: 'invibes_nouvelobsprogrammetv_top',
  },
  rubicon: {
    teleobs: {
      siteId: 423106,
      placements: {
        banniere_haute: { tag: 2403874 },
        banniere_basse: { tag: 2403882 },
        pave_haut: { tag: 2403878 },
        pave_milieu: { tag: 2403880 },
        inread: { tag: 2403884 },
        inread_top: { tag: 2403884 },
      },
    },
  },
  smart: {
    teleobs: {
      siteId: 502057,
      pageId: 1567065,
    },
  },
  sublime: {
    teleobs: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 36507 },
        },
        mobile: {
          cover: { zoneId: 36508 },
        },
      },
    },
  },
  teads: {
    teleobs: { pageId: 138814, placementId: 152304 },
  },
  triplelift: {
    teleobs: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'ProgTV_HB_',
    },
  },
};
