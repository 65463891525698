/* eslint-disable import/no-cycle */
/* global window, document, googletag, pbjs, requestAnimationFrame,
sas, performance, MutationObserver */
import { config } from '../../wrapperConfig';
import {
  detectGroup,
  detectBreakPoint,
} from './detect';
import { formats as defaultFormats } from './formats';
import { formats as radioFranceFormats } from './formats-rf';
import SiteFactory from './sites';

export const getParameters = (name, url = window.location.href) => {
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(url);

  return (results === null) ? null : results[1];
};

export const hasDebug = (name) => {
  const debugValue = getParameters('glmawdb');

  if (debugValue !== null) {
    return debugValue.split('|').includes(name);
  }

  return false;
};

export const log = (data) => {
  if (typeof data !== 'object') {
    data = [data];
  }

  /* eslint-disable no-console */
  if (console && typeof console.log === 'function' && hasDebug('console')) {
    console.log(...[
      '%cGLMAW',
      'display: inline-block; color: #61d2a3; background: #193a4a; padding: 1px 4px; border-radius: 3px;',
      ...data,
    ]);
  }
  /* eslint-enable no-console */
};

export const loadScript = (url, async = true) => new Promise((resolve, reject) => {
  // Is script already loading
  let dirty = false;

  const script = document.createElement('script');
  if (async) {
    script.async = true;
  } else {
    script.async = false; // means async-download but ordered-execution!
  }
  script.src = url;
  script.type = 'text/javascript';
  script.classList.add('glmaw-loaded-script');

  script.onload = () => {
    if (!dirty && (!script.readyState || script.readyState === 'loaded' || script.readyState === 'complete')) {
      dirty = true;
      script.onload = null;
      script.onreadystatechange = null;

      resolve(script);
    }
  };

  script.onreadystatechange = script.onload;
  script.onerror = reject;

  document.head.appendChild(script);
});

// Remove all scripts with class glmaw-loaded-script
export const clearScript = () => {
  const scripts = document.querySelectorAll('.glmaw-loaded-script');

  scripts.forEach((script) => {
    script.parentNode.removeChild(script);
  });
};

export const readCookie = (cookieDebugName) => {
  const cookie = `;${document.cookie}`.match(`;\\s*${cookieDebugName}=([^;]+)`);

  return cookie !== null ? cookie[1] : false;
};

export const throttle = (callback, wait) => {
  let time = Date.now();

  return () => {
    if (((time + wait) - Date.now()) < 0) {
      callback();
      time = Date.now();
    }
  };
};

export const extend = (initial, adds) => {
  Object.keys(adds).forEach((key) => {
    if (typeof initial[key] === 'undefined') {
      initial[key] = adds[key];
    } else if (typeof adds[key] !== typeof initial[key]) {
      log(`bad configuration type for ${key}, must be an instance of ${typeof initial[key]}`);
    } else if (typeof adds[key] === 'object') {
      extend(initial[key], adds[key]);
    } else {
      initial[key] = adds[key];
    }
  });

  return initial;
};

export const calculateCpmBucket = (bidResponse) => {
  let cpmStr = '';

  const { cpm } = bidResponse;
  const granularityMultiplier = 1;
  const buckets = (bidResponse.adUnitCode in config.prebid.granularities)
    ? config.prebid.granularities[bidResponse.adUnitCode]
    : config.prebid.granularities.default;

  const cap = buckets.reduce((prev, curr) => {
    if (prev.max > curr.max) {
      return prev;
    }

    return curr;
  }, {
    max: 0,
  });

  const bucket = buckets.find((item) => {
    if (cpm >= cap.max * granularityMultiplier) {
      const precision = item.precision || config.prebid.defaultPrecision;
      cpmStr = (item.max * granularityMultiplier).toFixed(precision);
    } else if (cpm < item.max * granularityMultiplier && cpm >= item.min * granularityMultiplier) {
      return item;
    }

    return false;
  });

  if (bucket) {
    const bucketSize = 1 / (bucket.increment * granularityMultiplier);
    const precision = bucket.precision || config.prebid.defaultPrecision;
    cpmStr = ((Math.floor(cpm * bucketSize) / bucketSize) + bucket.increment).toFixed(precision);
  }

  log(`${cpmStr} cpm for ${bidResponse.bidder}`);
  return cpmStr;
};

export const filterFormats = (initial, given, excluded) => {
  const result = {};

  Object.keys(initial).forEach((k1) => {
    if (typeof given[k1] !== 'undefined') {
      Object.keys(given[k1]).forEach((k2) => {
        initial[k1][k2] = given[k1][k2];
      });
    }

    if (typeof excluded[k1] === 'undefined') {
      result[k1] = initial[k1];
    } else {
      const subset = {};

      Object.keys(initial[k1]).forEach((k2) => {
        if (typeof excluded[k1][k2] === 'undefined') {
          subset[k2] = initial[k1][k2];
        }

        if (Array.isArray(initial[k1][k2]) && Array.isArray(excluded[k1][k2])) {
          const excls = excluded[k1][k2].map((entry) => entry.toString());
          const formats = initial[k1][k2].filter((entry) => excls.indexOf(entry.toString()) === -1);

          subset[k2] = formats;
        }
      });

      result[k1] = subset;
    }
  });

  return result;
};

let slotIds = {};
export const getNextIdBySlotFormat = (format) => {
  if (typeof slotIds[format] === 'undefined') {
    slotIds[format] = 0;
  }

  slotIds[format] += 1;

  return slotIds[format] === 1 ? format : `${format}-${slotIds[format]}`;
};

export const getSlotByElementId = (elementId) => config.gamSlots
  .filter((slot) => slot.getSlotElementId() === elementId)
  .shift();

export const getSlotsByLineItemId = (lineItemId) => config.gamSlots
  .filter((slot) => slot.lineItemId === lineItemId);

export const isElementComingInViewport = (node) => {
  const element = document.querySelector(`#${node.getSlotElementId()}`);

  if (!element) {
    return false;
  }

  const elementPosition = element.getBoundingClientRect().top;
  const threshold = window.innerHeight + config.lazyload.threshold;

  // If the element is above the threshold, then it is "about to come into view"
  return elementPosition < threshold;
};

export const setAdUnit = () => {
  let adUnit = '';

  if (typeof window.ADS_CONFIG !== 'undefined') {
    if (typeof window.ADS_CONFIG.adUnit !== 'undefined') {
      log(`set '${window.ADS_CONFIG.adUnit}' as ad unit`);
      adUnit = window.ADS_CONFIG.adUnit;
    }
  }

  return adUnit;
};

// Return an array in which slots are sorted in a defined priority order
export const sortSlots = () => {
  const slots = [];

  document.querySelectorAll(config.slots).forEach((slot) => {
    const slotName = slot.getAttribute('data-format');
    switch (slotName) {
      case 'cover':
        slot.priorityOrder = 1;
        slots.push(slot);
        break;
      case 'banniere_haute':
        slot.priorityOrder = 2;
        slots.push(slot);
        break;
      case 'pave_haut':
        slot.priorityOrder = 3;
        slots.push(slot);
        break;
      case 'inread_top':
        slot.priorityOrder = 4;
        slots.push(slot);
        break;
      default:
        slot.priorityOrder = 5;
        slots.push(slot);
    }
  });

  const compare = (a, b) => {
    if (a.priorityOrder < b.priorityOrder) {
      return -1;
    }
    if (a.priorityOrder > b.priorityOrder) {
      return 1;
    }
    return 0;
  };

  slots.sort(compare);

  return slots;
};

export const setTargeting = () => {
  let Targeting = {
    nt: (() => {
      if (
        typeof performance !== 'undefined'
        && typeof performance.navigation !== 'undefined'
        && typeof performance.navigation.type !== 'undefined'
      ) {
        return performance.navigation.type;
      }

      if (document.referrer === window.location.href) {
        return 1;
      }

      return 0;
    })(),
  };

  if (typeof window.ADS_CONFIG !== 'undefined' && typeof window.ADS_CONFIG.targets !== 'undefined') {
    log('set targeting');
    Targeting = {
      ...Targeting,
      ...window.ADS_CONFIG.targets,
    };
  }

  return Targeting;
};

export const setModules = () => {
  const modules = [
    'appnexus',
    'criteo',
    'invibes',
    'ix',
    'rubicon',
    'smartadserver',
    'sublime',
    'teads',
    'triplelift',
  ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.getAll('ssp');

  if (entries.length > 0) {
    entries.forEach((entry) => {
      if (modules.indexOf(entry) > -1) {
        modules.splice(modules.indexOf(entry), 1);
      }
    });

    return modules;
  }

  if (typeof window.ADS_CONFIG !== 'undefined' && typeof window.ADS_CONFIG.modules !== 'undefined') {
    return window.ADS_CONFIG.modules;
  }

  return modules;
};

// Set a custom refresh interval through the query string parameter 'refresh'
export const customRefreshInterval = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const customInterval = urlParams.get('refresh');

  if (typeof customInterval !== 'undefined') {
    const newInterval = parseInt(customInterval, 10);

    if (newInterval >= 9) {
      log(`set custom refresh interval to ${newInterval} seconds`);
      config.refresh.refreshIntervalDesktop = newInterval;
      config.refresh.refreshIntervalMobile = newInterval;
    }
  }

  return false;
};

export const removeUnloadedClassName = (configuration) => {
  const gamSlots = document.querySelectorAll(configuration.slots);
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length > 0
        && mutation.target.className.includes(configuration.unloadedClassName)) {
        const adsLoaded = document.getElementById(mutation.target.id);
        adsLoaded.classList.remove(configuration.unloadedClassName);
        log(`${mutation.target.id} -> remove class ${configuration.unloadedClassName}`);
      }
    });
  });
  gamSlots.forEach((gamSlot) => {
    gamSlot.classList.remove(configuration.loadingClassName);
    observer.observe(gamSlot, { childList: true, subtree: true });
  });
};

export const resetWrapper = () => {
  const { wrapperType } = config;
  clearScript();

  if (typeof window.ADS_CONFIG !== 'undefined') {
    if (typeof window.ADS_CONFIG.adUnit !== 'undefined') {
      log(`update '${window.ADS_CONFIG.adUnit}' as ad unit`);
      config.adUnit = window.ADS_CONFIG.adUnit;
    }

    config.targets = setTargeting();

    if (wrapperType === 'consentless') {
      // Check if sas object is already running, then refresh existing slots
      if (typeof sas !== 'undefined') {
        sas.refresh();
      }
      window.consentless.initConsentless();
      log('consentless.js has destroyed existing slots and reset');
    }

    if (wrapperType === 'consent') {
      // Reset slots IDs to an empty object
      slotIds = {};
      // Check if googletag object is already running, then destroy existing slots
      if (typeof googletag !== 'undefined') {
        pbjs.adUnits = [];
        googletag.destroySlots();
      }
      window.glmaw.initWrapper();
      log('glmaw.js has destroyed existing ad units and reset');
    }
  }
};

// Detect if element is loaded in the DOM tree
export function elementReady(selector) {
  return new Promise((resolve) => {
    const el = document.querySelector(selector);
    if (el) { resolve(el); }
    new MutationObserver((mutationRecords, observer) => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
        resetWrapper();
        observer.disconnect();
      }
    })
      .observe(document.documentElement, {
        childList: true,
        subtree: true,
      });
  });
}

// Update the wrapper on route change
export const updateWrapper = () => {
  const { slots } = config;
  let url = window.location.href;

  // Detect route change
  document.addEventListener('click', () => {
    requestAnimationFrame(() => {
      if (url !== window.location.href) {
        url = window.location.href;
        elementReady(slots);
      }
    });
  }, true);
};

// Check if ad slots are loaded in the DOM
export const detectSlots = () => {
  const slots = document.querySelectorAll(config.slots);

  if (slots.length > 0) {
    return true;
  }

  return false;
};

// Return the wrapper loading time
export const displayLoadingTime = (start) => {
  const end = window.performance.now();
  config.loadingTime = Math.round(end - start);

  if (config.site === 'glmaw') {
    const timer = document.getElementById('glmaw-timer-placeholder');

    if (timer !== null) {
      timer.innerHTML = `${config.loadingTime}ms`;
    }
  }

  log(`GLMAW loaded in ${config.loadingTime}ms`);
};

// Merge default config with specific config
export const mergeConfigs = () => {
  // Detect to which group (Groupe Le Monde ou Radio France) the current website belongs
  detectGroup();
  // Return device type depending on breakpoint (for Radio France websites);
  detectBreakPoint();

  // For Huffpost staging
  const { hostname } = window.document.location;
  if (hostname === 'www.stg-huffpost.loc-lemonde.fr') {
    config.site = 'huffpost';
  }

  if (window.location.href.includes('teleobs')) {
    config.site = 'teleobs';
  }

  const specificConfig = SiteFactory().getSpecificConfig(config.site);

  if (config.group === 'radiofrance') {
    config.formats = radioFranceFormats;
    config.criteo.formats = [
      'banniere_haute',
      'pave_haut',
      'inread',
      'inread_top',
      'pave_bas',
    ];

    if (config.site === 'francebleu') {
      config.criteo.formats = [
        'banniere_haute',
        'pave_haut',
        'inread',
        'inread_top',
        'pave_bas',
        'banniere_basse',
      ];
    }

    extend(config, specificConfig);
  } else {
    config.formats = filterFormats(
      defaultFormats,
      specificConfig.formats || {},
      {},
    );

    if (config.site === 'lemonde') {
      config.refresh.excludedFormats = [
        'banniere_haute',
        'pave_haut',
        'cover',
        'inread',
        'inread_top',
      ];

      if (config.adUnit.includes('LM_lemonde_abo')) {
        config.formats.banniere_haute.desktop = [
          [728, 90],
          [970, 250],
          [970, 90],
          [1000, 90],
          [1000, 300],
        ];
      }
    }

    extend(config, specificConfig);
  }
};

// Update config on route change for the SPA-app
export const onRouteChange = () => {
  let url = window.location.href;
  if (config.site === 'glmaw') {
    delete config.pbjsConfig.consentManagement;

    document.body.addEventListener('click', () => {
      requestAnimationFrame(() => {
        if (url !== window.location.href) {
          url = window.location.href;
          mergeConfigs();
        }
      });
    }, true);
  }
};
