/* eslint-disable import/no-cycle */
import {
  getParameters,
  setAdUnit,
  setTargeting,
  setModules,
  mergeConfigs,
  onRouteChange,
} from './lib/utils';
import { detectSite, detectDevice } from './lib/utils/detect';
import SiteFactory from './lib/utils/sites';

export const config = {
  group: '',
  site: detectSite(SiteFactory().getSites()),
  device: detectDevice(),
  adUnit: setAdUnit(),
  targets: setTargeting(),
  slots: '.adSlot',
  unloadedClassName: 'dfp-unloaded',
  loadingClassName: 'dfp-loading',
  formats: {},
  excludedFormatsHB: ['inimage_seed'],
  wrapperType: '',
  ppidCookieName: 'lead',
  lazyload: {
    threshold: 150,
    excludedFormats: [
      'cover',
      'pave_haut',
      'banniere_haute',
    ],
  },
  refresh: {
    inViewPercentage: 50,
    maxInactivity: 90_000,
    minDisplayTime: 35_000,
    minSinceActive: 5000,
    minSinceViewed: 5000,
    respectRoadblocks: true,
    tick: 500,
    excludedFormats: [],
    maxUserInactivity: 90,
    refreshIntervalDesktop: 25,
    refreshIntervalMobile: 20,
    checkSlotInterval: 1,
  },
  // Analytics scripts URLs
  pubstack: '//boot.pbstck.com/v1/tag/b9725a8b-9a93-4740-aaba-5e304966592e',
  grapeshot: '//le-monde-ams.gscontxt.net/main/channels.cgi?url=',
  // Overall timeout
  timeout: 3000,
  // Prebid configs
  prebid: {
    defaultPrecision: 2,
    granularities: {
      default: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01,
        }, {
          precision: 2,
          min: 5,
          max: 20,
          increment: 0.05,
        }, {
          precision: 2,
          min: 20,
          max: 70,
          increment: 0.5,
        },
      ],
    },
    modules: setModules(),
    testScenario: getParameters('testScenario') || '',
    // Prebid request timeout
    timeout: 3000,
    version: '6.10.0',
  },
  pbjsConfig: {
    consentManagement: {
      gdpr: {
        cmpApi: 'iab',
        timeout: 8000,
        allowAuctionWithoutConsent: true,
        defaultGdprScope: true,
      },
    },
    // Prebid failsafe timeout
    bidderTimeout: 3000,
    rubicon: {
      singleRequest: true,
    },
    currency: {
      adServerCurrency: 'EUR',
      granularityMultiplier: 1,
      defaultRates: { USD: { EUR: 0.89 } },
    },
    useBidCache: true,
    cache: {
      url: 'https://prebid.adnxs.com/pbc/v1/cache',
    },
    // For Smile Wanted config
    userSync: {
      iframeEnable: true,
      filterSettings: {
        iframe: {
          bidders: '*',
          filter: 'include',
        },
        image: {
          bidders: '*',
          filter: 'include',
        },
      },
      syncDelay: 3000,
      syncEnabled: true,
      syncsPerBidder: 5,
    },
  },
  // Prebid SSPs modules configs
  appnexus: {
    member: 8499,
    adaptor: 'appnexus',
  },
  criteo: {
    adaptor: 'criteo',
    networkId: 8230,
    formats: [
      'banniere_haute',
      'pave_haut',
      'inread_top',
      'inread',
      'banniere_milieu',
      'banniere_basse',
      'pave_milieu',
      'pave_bas',
    ],
  },
  indexExchange: {
    adaptor: 'ix',
    size: [640, 480],
    video: {
      mimes: [
        'video/mp4',
        'video/webm',
        'video/mpg',
        'application/javascript',
      ],
      w: 640,
      h: 480,
      startdelay: 0,
      minduration: 5,
      maxduration: 180,
      protocols: [2, 3, 5, 6],
      api: [1, 2],
    },
  },
  invibes: {
    adaptor: 'invibes',
    formats: [
      'inread_top',
      'inread',
    ],
    endpoint: 'https://bid5.videostep.com/Bid/VideoAdContent',
  },
  rubicon: {
    accountId: 19358,
    adaptor: 'rubicon',
  },
  smart: {
    adaptor: 'smartadserver',
    formats: {
      banniere_haute: { formatId: 70664 },
      banniere_milieu: { formatId: 90457 },
      banniere_basse: { formatId: 70665 },
      pave_haut: { formatId: 70666 },
      pave_milieu: { formatId: 70667 },
      pave_bas: { formatId: 70668 },
      inread: { formatId: 70670 },
      inread_top: { formatId: 114273 },
    },
  },
  sublime: {
    adaptor: 'sublime',
  },
  teads: {
    adaptor: 'teads',
    formats: [
      'inread',
      'inread_top',
    ],
  },
  triplelift: {
    adaptor: 'triplelift',
    overridedSizes: {},
  },
  // Amazon header bidding (TAM) config
  amazonScript: '//c.amazon-adsystem.com/aax2/apstag.js',
  amazonConfig: {
    pubID: '3789',
    adServer: 'googletag',
    videoAdServer: 'DFP',
    deals: true,
  },
  requestAmzVideoDone: false,
  // Smart Consentless config
  smartConsentlessScript: '//ced.sascdn.com/tag/3937/smart.js',
  smartConsentlessData: '',
  smartAdCallConfig: {},
  smartAdNetworkid: 3937,
  smartAdDomain: '//diff.smartadserver.com',
  // Staging scripts URLs
  stagingScript: 'https://wrapper.lemde.fr/v2/glmaw-staging.js',
  stagingConsentlessScript: 'https://wrapper.lemde.fr/v2/consentless-staging.js',
};

// Merge inherited config with site-specific config
mergeConfigs();
// For test-SPA only, update & merge configs on route change
onRouteChange();
