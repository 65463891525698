/* eslint-disable arrow-body-style, arrow-parens */
/* global window, document, fetch, sas */
import { config } from '../wrapperConfig';
import { log, removeUnloadedClassName } from '../lib/utils';

const defaultAdUnit = () => {
  if (typeof config.smartAdCallConfig[config.adUnit.slice(11)] !== 'undefined') {
    return config.smartAdCallConfig[config.adUnit.slice(11)];
  }
  return config.smartAdCallConfig.default;
};

const pushRenderSmartAdUnit = () => {
  const slotIds = document.querySelectorAll(config.slots);
  slotIds.forEach((slotId) => {
    sas.cmd.push(() => {
      log('formatsAdUnit', slotId.id);
      sas.render(slotId.id);
    });
  });
};

export const getSmartConsentlessData = () => new Promise((resolve) => {
  fetch(config.smartConsentlessData)
    .then((res) => res.json())
    .then((data) => {
      config.smartAdCallConfig = data.smartAdCallConfig;
      resolve();
    })
    .catch((err) => resolve(err));
});

export const renameInreadId = () => {
  const inreads = document.querySelectorAll('[data-format="inread"]');
  inreads.forEach((inread, index) => {
    if (index === 0) {
      inread.setAttribute('id', 'inread');
    } else {
      inread.setAttribute('id', `inread_${index + 1}`);
    }
  });
};

export const initConfigSmart = () => {
  window.sas = window.sas || {};
  window.sas.cmd = window.sas.cmd || [];
  sas.cmd.push(() => {
    sas.setup({
      networkid: config.smartAdNetworkid,
      domain: config.smartAdDomain,
      async: true,
    });
  });
};

export const executeSmartDecision = () => {
  sas.callSmart = () => {
    log('call to Smart');

    const smartConfig = defaultAdUnit();
    smartConfig.target = Object.keys(config.targets).map((index) => {
      if (!Array.isArray(config.targets[index])) {
        return `${index}=${config.targets[index]}`;
      }
      return `${index}=${config.targets[index].map((value) => value)}`;
    }).join(';');

    sas.cmd.push(() => {
      sas.call('onecall', smartConfig);
    });
    pushRenderSmartAdUnit();
  };
  sas.callSmart();
  removeUnloadedClassName(config);
};
