/* eslint-disable no-restricted-globals */
import { config } from './wrapperConfig';
import { updateWrapper, resetWrapper } from './lib/utils';
import { toggleConsentlessStaging } from './modules/debug';
import { init as initConsentless } from './initConsentless';

initConsentless();
// On route change, ads slots are destroyed and the wrapper is reset
config.wrapperType = 'consentless';
updateWrapper();

export {
  config,
  toggleConsentlessStaging as staging,
  initConsentless,
  resetWrapper,
};
